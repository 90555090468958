@import 'styles/variables';

.block {
  // padding-bottom: 100px;
  padding: 120px 0;
  background-color: white;
}

.blockWrapper {
  display: flex;
  justify-content: space-around;
}

.content {
  width: 400px;
}

.title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: $smallTitleFontSize;
  line-height: 52px;
  color: $colorTextBlackMain;
  margin-bottom: 15px;
}

.subheadingText {
  font-size: $mediumFontSize;
  line-height: 24px;
  color: $colorTextBlackMain;
  opacity: 0.6;
  width: 305px;
  margin-bottom: 40px;
}

.tab {
  display: flex;
  padding-bottom: 30px;
  opacity: 0.8;
}

.tabActive {
  opacity: 1;
}

.number {
  width: 32px;
  margin-right: 20px;

  span {
    display: block;
    width: 32px;
    height: 32px;
    background: #f6f8fb;
    border-radius: 4px;
    text-align: center;
    line-height: 32px;
    font-size: $mediumFontSize;
    color: #565561;
    cursor: pointer;
  }

  div {
    height: calc(100% - 2px);
    width: 2px;
    background: $colorLightGray;
    margin: 0 auto;
  }
}

.tabContent {
  p {
    font-family: 'Inter', serif;
    font-size: $xsFontSize;
    line-height: 20px;
    color: #2b2b39;
    opacity: 0.5;
    max-height: 0;
    overflow: hidden;
  }

  .openedTab {
    animation: open-tab ease-in 0.2s;
    max-height: 130px;
  }

  .closedTab {
    animation: close-tab ease-in 0.2s;
    max-height: 0;
  }

  h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: $xxlFontSize;
    line-height: 31px;
    color: $colorTextBlack;
    cursor: pointer;
    margin-bottom: 10px;
  }
}

.guideListWrapper {
  position: relative;
  min-height: 430px;
}

.openedTabNumber {
  span {
    animation: open-tab-btn ease-in 0.2s;
    background: #1168f1;
    color: $colorTextLightGrayMain;
    cursor: default;
  }
}

@keyframes open-tab-btn {
  0% {
    background: #f6f8fb;
    color: #565561;
  }

  100% {
    background: #1168f1;
    color: $colorTextLightGrayMain;
  }
}

@keyframes open-tab {
  0% {
    max-height: 0;
    opacity: 0;
  }

  10% {
    max-height: 0;
    opacity: 0;
  }

  100% {
    max-height: 130px;
    opacity: 0.5;
  }
}

@keyframes close-tab {
  0% {
    max-height: 130px;
  }

  80% {
    max-height: 0;
  }

  100% {
    max-height: 0;
  }
}

.button {
  background: $colorGold;
  position: absolute;
  top: 100%;
  border-radius: 10px;
  width: 207px;
  height: 48px;
  border: none;
  font-weight: bold;
  font-size: $mediumFontSize;
  line-height: 24px;
  color: #fefefe;
  cursor: pointer;

  &:hover {
    background: $colorGoldActive;
  }
}

.videoBlock {
  margin-left: 15px;
  width: 650px;
  height: 532px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.videoItem {
  position: absolute;
  top: -3px;
  right: -4px;
  left: -3px;
  z-index: 0;
  width: 104%;
  opacity: 0;
  border-radius: 4px;
}

.videoItemActive {
  z-index: 1;
  opacity: 1;
}

@media (--mobile) {
  .button {
    width: 100%;
    top: 109%;
  }

  .content {
    width: 100%;
  }

  .guideListWrapper {
    min-height: 400px;
  }
}

@media (--xs-tablet) {
  .content {
    width: 700px;
  }

  .button {
    width: 100%;
  }
}
