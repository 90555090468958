@import 'src/styles/variables';

.whyLoanNeeded {
  margin-top: 80px;
}

.title {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: $smallTitleFontSize;
  line-height: 52px;
  text-align: center;
  color: $colorTextBlackMain;
  margin-bottom: 16px;
}

.description {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  font-size: $mediumFontSize;
  line-height: 24px;
  color: $colorTextBlackMain;
  opacity: 0.6;
  margin-bottom: 40px;
}

.cardList {
  display: flex;
  justify-content: center;
}

.cardItem {
  width: 282px;
  min-height: 246px;
  margin: 0 12px;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 34px 24px;
  background: linear-gradient(180deg, rgba(17, 103, 240, 0.1) 0%, rgba(255, 255, 255, 0.1) 132.64%);
  border-radius: 30px;
}

.cardItemTitle {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  color: $colorTextBlackMain;
  font-size: $xxlFontSize;
}

.cardItemIcon {
  margin-right: 8px;
}

.cardItemDescription {
  margin-top: 26px;
  font-size: $xsFontSize;
  color: $colorTextBlackMain;
  opacity: 0.6;
}

.cardItemHead {
  display: flex;
  align-items: center;
}

@media (--mobile), (--xs-tablet), (--tablet) {
  .cardList {
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
  }

  .description,
  .title {
    padding: 0 5px;
    text-align: start;
  }

  .description {
    justify-content: start;
    margin-bottom: 32px;
  }

  .cardItem {
    margin: 8px 0;
  }

  .cardItemDescription {
    margin-top: 22px;
  }
}
